import React, { ReactElement } from "react";
import RightArrow from "@components/svg/rightArrow";
import styles from "./styles.module.scss";

export default function BottomSection(): ReactElement {
    const scrollToTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };
    return (
        <section className={`mt-10 mb-10 lg:mt-16 ${styles["bottomSection"]}`}>
            <div className={`${styles["bottomSectionWrapper"]} text-center`}>
                <div className="max-w-7xl mx-auto block px-4vw lg:px-4">
                    <h2
                        className={`${styles["title"]} font-extrabold text-2xl md:text-4xl`}
                    >
                        Is There an Upfront Fee to File a Lawsuit?
                    </h2>
                    <p className={`${styles["brief"]} text-base md:text-xl`}>
                        Retaining a Lawyer costs you nothing out of pocket. We
                        don’t get paid unless your case is successful.
                    </p>
                </div>
            </div>
            <button onClick={() => scrollToTop()} className={styles.button}>
                <div className="flex items-center justify-center h-5">
                    <span>Get a Free Case Review</span>
                    <RightArrow className="w-4 h-auto" fill="white" />
                </div>
            </button>
        </section>
    );
}
